import { Injectable } from '@angular/core';

import { Lock } from '../../models/lock';
import { Key } from '../../models/key';

import { LockService } from '../lock/lock.service';
import { KeyService } from '../key/key.service';
import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';
import { alphabetizeBy } from '../utility/utility';
import { KeyMembershipService } from '../key-membership/key-membership.service';
import { KeyMembership } from '../../models/key-membership';
import { getMembershipName } from '../utility/presentation';
import { PermissionsService } from '../permissions/permissions.service';
import { Subject, forkJoin, zip, BehaviorSubject, of, Observable } from 'rxjs';
import { map, switchMap, tap, take } from 'rxjs/operators';
import { IntercomService } from '../intercom/intercom.service';
import { IntercomShortInfo } from 'manager/services/intercom/intercom.service';
import { FeatureService } from './feature.service';
import { VirtualIntercom } from '../../models/intercom';

export interface DoorsPersonRow {
  name: string;
  email: string;
  uuid: string;
}

/*
 * Manages data required by multiple tabs on a door's detail page.
 */
@Injectable()
export class DoorDetailPageService {

  isLoading = false;
  searchResultCount = new BehaviorSubject<Record<string, number>>({});

  lock!: Lock;
  keys!: Key[];
  /**
   * intercom$ allows clients to build observable streams that wait for the service to load the intercom for this door
   */
  public intercom$ = new BehaviorSubject<IntercomShortInfo | null>(null);
  /**
   * virtualIntercom$ allows clients to build observable streams that wait for the service to load the Latch Link for this door
   */
  public virtualIntercom$ = new BehaviorSubject<VirtualIntercom | null>(null);
  /** memberships$ allows clients to build observable streams that wait for the service to load all memberships for this door
   * this variable CAN return null before the memberships have loaded
   * clients are responsible for filtering to ensure all memberships have loaded before accessing values
   * EXAMPLE -->  ` filter(memberships => !!memberships)`
   */
  memberships$ = new BehaviorSubject<KeyMembership[] | null>(null);
  usersWithAccess$ = new BehaviorSubject<DoorsPersonRow[] | null>(null);
  usersWithAccess: DoorsPersonRow[] = [];
  // userId is key to the count of keys granting user access to this door
  userAccessThroughKeysCount = new Map<string, number>();

  /** Whether the currently logged in user has permission to edit the lock currently selected by this service. */
  currentUserCanEditDoors = false;
  /** Parent emits edit or cancel click event via service and child handles the event accordingly */
  private editClick = new Subject<boolean>();
  /** Parent emits save click event via service and child handles the event accordingly */
  private saveClick = new Subject<void>();

  buildingUUID!: string;

  constructor(
    private lockService: LockService,
    private keyService: KeyService,
    private analyticsService: LatchAnalyticsService,
    private permissionsService: PermissionsService,
    private keyMembershipService: KeyMembershipService,
    private intercomService: IntercomService,
    private featureService: FeatureService
  ) { }

  get intercom() {
    return this.intercom$.getValue();
  }

  set intercom(intercom) {
    this.intercom$.next(intercom);
  }

  get virtualIntercom() {
    return this.virtualIntercom$.getValue();
  }

  set virtualIntercom(virtualIntercom) {
    this.virtualIntercom$.next(virtualIntercom);
  }

  get memberships() {
    return this.memberships$.getValue();
  }

  set memberships(memberships) {
    this.memberships$.next(memberships);
  }

  loadPageData({ buildingUUID, lockUUID }: { buildingUUID: string, lockUUID: string; }) {
    // clear any existing data
    this.keys = [];
    this.memberships = null;
    this.usersWithAccess = [];
    this.userAccessThroughKeysCount.clear();
    this.buildingUUID = buildingUUID;

    const lock$ = this.lockService
      .getLockDetails(lockUUID).pipe(
        tap((lock) => this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
          [LatchAnalyticsConstants.PageName]: 'Door Detail',
          [LatchAnalyticsConstants.LockUUID]: lock.uuid,
          [LatchAnalyticsConstants.LockName]: lock.name
        }))
      );

    const keys$ = this.keyService.getKeys(buildingUUID).pipe(
      map((keys) => keys.filter((key) => key.doors.some(door => door.lockUUID === lockUUID)))
    );

    const complete$ = forkJoin([lock$, keys$]);

    const canEdit$ = this.permissionsService.currentUserMayEditDoors();

    const buildingHasIntercomFeature$ = this.featureService.hasIntercomFeature$.pipe(take(1));

    const buildingHasVirtualIntercomFeature$ = this.featureService.hasVirtualIntercomFeature$.pipe(take(1));

    return zip(complete$, canEdit$, buildingHasIntercomFeature$, buildingHasVirtualIntercomFeature$).pipe(
      tap(([[lock, keys], canEdit, __]) => {
        this.lock = lock;
        this.keys = alphabetizeBy(keys, 'name');
        this.currentUserCanEditDoors = canEdit;
      }),
      switchMap(([[lock, keys], __, buildingHasIntercomFeature, buildingHasVirtualIntercomFeature]) => {
        const intercom$ = buildingHasIntercomFeature ? this.intercomService.getIntercomForLock(lockUUID, buildingUUID) : of(null);
        const virtualIntercom$ = buildingHasVirtualIntercomFeature ?
          this.intercomService.getVirtualIntercomForLock(lockUUID, buildingUUID) : of(null);
        const getKeyMemberships$ = this.keyMembershipService.getKeyMemberships({
          buildingUUID,
          keyUUID: keys.map((key) => key.uuid)
        });
        return zip(intercom$, virtualIntercom$, getKeyMemberships$);
      }),
      tap(([intercom, virtualIntercom, memberships]) => {
        this.intercom = intercom;
        this.virtualIntercom = virtualIntercom;
        this.memberships = memberships;
        const usersWithAccessMap = new Map<string, DoorsPersonRow>();
        memberships.forEach((membership) => {
          if (this.userAccessThroughKeysCount.has(membership.userUUID)) {
            const currentCount = this.userAccessThroughKeysCount.get(membership.userUUID) ?? 0;
            this.userAccessThroughKeysCount.set(membership.userUUID, currentCount + 1);
          } else {
            this.userAccessThroughKeysCount.set(membership.userUUID, 1);
          }

          const userWithAccess = {
            name: getMembershipName(membership),
            email: membership.userEmail || '',
            uuid: membership.userUUID
          };
          if (!usersWithAccessMap.has(userWithAccess.uuid)) {
            usersWithAccessMap.set(userWithAccess.uuid, userWithAccess);
          }
        });
        this.usersWithAccess = Array.from(usersWithAccessMap.values());
        this.usersWithAccess$.next(this.usersWithAccess);
      }),
    );
  }

  /**
   * emit edit click event to be handled accordingly
   */
  public emitEditClick(): void {
    this.editClick.next(true);
  }

  /**
   * emit cancel click event to be handled accordingly
   */
  public emitCancelClick(): void {
    this.editClick.next(false);
  }

  /**
   * returns observable that emits true when the edit is clicked and false when the cancel is clicked
   */
  public getEditClick(): Observable<boolean> {
    return this.editClick.asObservable();
  }

  /**
   * emit save click event to be handled accordingly
   */
  public emitSaveClick(): void {
    this.saveClick.next();
  }

  /**
   * returns observable that emits when the save is clicked
   */
  public getSaveClick(): Observable<void> {
    return this.saveClick.asObservable();
  }
}
