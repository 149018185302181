import { LatchSelectionItem } from '@latch/latch-web';

export enum DisplayNamePreference {
  Default = 'DEFAULT',
  Custom = 'CUSTOM'
}

export const DisplayNamePreferenceOptions: LatchSelectionItem[] = [
  { name: 'Default name', value: DisplayNamePreference.Default },
  { name: 'Custom name', value: DisplayNamePreference.Custom },
];

export enum IntercomManagementDisplaySettings {
  Top = 'TOP',
  Bottom = 'BOTTOM'
}

export enum IntercomNetworkConnection {
  ETHERNET = 'ETHERNET',
  WIFI = 'WIFI',
  CELLULAR = 'CELLULAR'
}

export type IntercomOption = 'None' | 'Intercom' | 'Latch Link';
export interface UserIntercomSettings {
  contactCardUUID: string;
  isVisible: boolean;
  namePreference?: DisplayNamePreference;
  customName?: string;
}

export enum ResidentContactType {
  APP,
  PHONE
}

export enum ResidentWarning {
  NONE = 'NONE',
  NEVER_LOGGED_INTO_APP = 'NEVER_LOGGED_INTO_APP'
}

export enum DefaultNameFormattingType {
  FIRST_NAME_LAST_INITIAL = 'FIRST_NAME_LAST_INITIAL',
  FIRST_INITIAL_LAST_NAME = 'FIRST_INITIAL_LAST_NAME',
  FIRST_NAME_LAST_NAME = 'FIRST_NAME_LAST_NAME'
}

export enum DirectoryListType {
  UNIT_LIST = 'UNIT_LIST',
  RESIDENT_LIST_WITH_UNIT = 'RESIDENT_LIST_WITH_UNIT',
  RESIDENT_LIST_WITHOUT_UNIT = 'RESIDENT_LIST_WITHOUT_UNIT'
}

export enum DirectorySortType {
  ALPHANUMERIC = 'ALPHANUMERIC',
  CUSTOM = 'CUSTOM'
}

export interface IntercomUnit {
  id: number;
  visible: boolean;
}

// Generic traits for each intercom connection method (wifi, cellular, ethernet)
export interface IntercomConnectionStatusDetails {
  enabled: boolean;
  connected: boolean;
}

export enum UpdateIntercomConnectionStatus {
  OK = 'OK',
  CONNECTING = 'CONNECTING',
  SETTINGS_OK_UNABLE_TO_CONNECT = 'SETTINGS_OK_UNABLE_TO_CONNECT',
  ERROR_SENDING_SETTINGS = 'ERROR_SENDING_SETTINGS',
  INVALID_ETHERNET_SETTINGS = 'INVALID_ETHERNET_SETTINGS',
  INVALID_WIFI_SETTINGS = 'INVALID_WIFI_SETTINGS',
  INVALID_CELLULAR_SETTINGS = 'INVALID_CELLULAR_SETTINGS',
  ERROR_ENABLING_ETHERNET = 'ERROR_ENABLING_ETHERNET',
  ERROR_ENABLING_WIFI = 'ERROR_ENABLING_WIFI',
  ERROR_ENABLING_CELLULAR = 'ERROR_ENABLING_CELLULAR',
  ERROR_DISABLING_ETHERNET = 'ERROR_DISABLING_ETHERNET',
  ERROR_DISABLING_WIFI = 'ERROR_DISABLING_WIFI',
  ERROR_DISABLING_CELLULAR = 'ERROR_DISABLING_CELLULAR'
}

export enum WifiSecurityType {
  UNSECURED = 'UNSECURED',
  WPA = 'WPA',
  WPA2 = 'WPA2'
}

// Wifi and ethernet may have extra configuration fields
export interface WifiConnectionStatusDetails extends IntercomConnectionStatusDetails {
  configuration?: {
    securityType: WifiSecurityType;
    ssid: string;
  };
}

export enum EthernetNetworkType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC'
}

export interface EthernetConnectionStatusDetails extends IntercomConnectionStatusDetails {
  configuration?: {
    networkType: EthernetNetworkType;
    ethernetGateway: string;
    ethernetSubnetMask: string;
    ethernetStaticIp: string;
  };
}

export enum SimCardStatus {
  READY = 'READY',
  NOT_READY = 'NOT_READY'
}

export interface IntercomConnectionStatus {
  connected: boolean;
  lastUnlocked?: number; // epoch seconds
  simCardStatus: string;
  ethernet: EthernetConnectionStatusDetails;
  wifi: WifiConnectionStatusDetails;
  cellular: IntercomConnectionStatusDetails;
}

export interface IntercomManager {
  id: number;
  contactCardUUID: string;
  displayName: string;
  firstName: string;
  lastName: string | undefined;
}

export interface IntercomManagement {
  displaySettings: IntercomManagementDisplaySettings;
  managers: IntercomManager[];
}

export interface Intercom {
  intercomId: number;
  lockUUID: string;
  parrotSerialNumber: string | null;
  units: IntercomUnit[];
  status: IntercomConnectionStatus;
  managers: IntercomManagement;
}

export interface VirtualIntercom {
  uuid: string;
  serialNumber: string;
  lockUUID: string;
  url: string;
  spamCallCount: number;
}

export interface NameFormattingSelect {
  name: string;
  value: DefaultNameFormattingType;
}

export interface DirectoryListSelect {
  name: string;
  value: DirectoryListType;
}

export interface DirectorySortSelect {
  name: string;
  value: DirectorySortType;
}

export interface BuildingIntercomSettings {
  defaultNameFormattingType: DefaultNameFormattingType;
  buildingUUID: string;
  customNamesDisabled?: boolean;
  directoryListType: DirectoryListType;
  directorySortType: DirectorySortType;
}
