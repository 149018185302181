import { Injectable } from '@angular/core';
import { BuildingFeature } from 'manager/models/building';
import { SelectedBuildingsService } from './selected-buildings.service';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeminiPartner, GeminiPartnerName } from 'manager/models/gemini-partner';
import { getGeminiPartnerId } from 'manager/services/utility/gemini-partner-utility';
import { SplitService } from '@splitsoftware/splitio-angular';
import { environment } from 'environments/interface';

@Injectable({ providedIn: 'root' })
export class FeatureService {

  public get hasDoorcodeSuppression$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.DoorcodeSuppression);
  }

  public get hasIntercomFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Intercom);
  }

  public get hasVirtualIntercomFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.VirtualIntercom);
  }

  public get hasSmartHomeFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.SmartHome);
  }

  public get hasVisualAccessLogsFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.VisualAccessLogs);
  }

  public get hasDeliveryAssistantFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.DeliveryAssistant);
  }

  public get hasPackageManagementFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.PackageManagement);
  }

  public get hasBundlesFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Bundling);
  }

  // placeholder flag for unit feature until it is rolled out to all buildings
  public get hasUnitFeature$(): Observable<boolean> {
    return combineLatest([
      this.hasIntercomFeature$,
      this.hasSmartHomeFeature$,
      this.hasLeasePayOrRentPayFeature$,
      this.hasPmsManagedUnitUpdateFeature$,
    ]).pipe(
      map(([
        buildingHasIntercomFeature,
        buildingHasSmartHomeFeature,
        buildingHasRentPaymentsFeature,
        buildingHasPmsManagedUnitUpdateFeature,
      ]) =>
        buildingHasIntercomFeature ||
        buildingHasSmartHomeFeature ||
        buildingHasRentPaymentsFeature ||
        buildingHasPmsManagedUnitUpdateFeature
      )
    );
  }

  // this getter solves the multiple emitted values issue with hasUnitFeature$ and will replace it in LMC-3735
  public get hasUnitFeatureV2$(): Observable<boolean> {
    return this.hasFeatures([
      BuildingFeature.Intercom,
      BuildingFeature.SmartHome,
      BuildingFeature.LeasePay,
      BuildingFeature.RentPay,
      BuildingFeature.PmsManagedUnitUpdate
    ]).pipe(
      map(hasFeatures => hasFeatures.some(x => x))
    );
  }

  public get hasSpacesFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Spaces);
  }

  // LeasePay is code-name for move-in payments (security deposit and first month's fees)
  public get hasLeasePayFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.LeasePay);
  }

  // RentPay is code-name for monthly rent payments
  public get hasRentPayFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.RentPay);
  }

  public get hasLeasePayOrRentPayFeature$(): Observable<boolean> {
    return combineLatest([
      this.hasLeasePayFeature$,
      this.hasRentPayFeature$,
    ]).pipe(
      map(([leasePayEnabled, rentPayEnabled]) => leasePayEnabled || rentPayEnabled)
    );
  }

  public get hasStripePaymentFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.StripePayment);
  }

  public get hasCommercialFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Commercial);
  }

  public get hasSmartHomeNestFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.SmartHomeNest);
  }

  public get hasSmartHomeEnterpriseManagementFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.SmartHomeEnterpriseManagement);
  }

  public get hasBookingsFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Bookings);
  }

  public get hasPaidBookingsFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.PaidBookings);
  }

  public get hasBookingsWithAccessFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.BookingsWithAccess);
  }

  public get hasBookingsTosFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.BookingsTos);
  }

  public get hasAccessAutomationFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.EnableAccessAutomation);
  }

  public get hasInsightsFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Insights);
  }

  public get hasConciergeFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Concierge);
  }

  public get hasInsightsDashboardFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.InsightsDashboard);
  }

  public get hasDoorSchedulesFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.DoorSchedules);
  }

  public get hasPmsManagedUnitUpdateFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.PmsManagedUnitUpdate);
  }

  public get hasPmsManagedFeature$(): Observable<boolean> {
    return combineLatest([
      this.hasFeature(BuildingFeature.PmsManaged),
      this.hasFeature(BuildingFeature.PmsManagedV2),
    ]).pipe(
      map(([v1Enabled, v2Enabled]) => v1Enabled || v2Enabled)
    );
  }

  public get hasServiceOrdersFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.ServiceOrders);
  }

  public get hasReportingFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.ReportingEnabled);
  }

  public get hasGeminiPartnerFeature$(): Observable<boolean> {
    return combineLatest([
      this.hasFeature(BuildingFeature.GeminiLatchPartner),
      this.hasFeature(BuildingFeature.GeminiJunePartner),
      this.hasFeature(BuildingFeature.GeminiHelloLandingPartner),
      this.hasFeature(BuildingFeature.GeminiCybersuitePartner),
      this.hasFeature(BuildingFeature.GeminiHelloLandingDevPartner),
      this.hasFeature(BuildingFeature.GeminiBehringPartner),
      this.hasFeature(BuildingFeature.GeminiBLinePartner),
      this.hasFeature(BuildingFeature.GeminiOrionHausPartner),
      this.hasFeature(BuildingFeature.GeminiJurnyPartner),
      this.hasFeature(BuildingFeature.GeminiElevatedLivingPartner),
      this.hasFeature(BuildingFeature.GeminiAirClutterPartner),
      this.hasFeature(BuildingFeature.GeminiTour24Partner),
      this.hasFeature(BuildingFeature.GeminiPynwheelPartner),
      this.hasFeature(BuildingFeature.GeminiLivlyPartner),
      this.hasFeature(BuildingFeature.GeminiCommunityBossPartner),
      this.hasFeature(BuildingFeature.GeminiPrimoPartner),
      this.hasFeature(BuildingFeature.GeminiEntratamationPartner),
      this.hasFeature(BuildingFeature.GeminiAvalonBaySelfGuidedTourPartner),
      this.hasFeature(BuildingFeature.GeminiChurchillPartner),
      this.hasFeature(BuildingFeature.GeminiVacasaPartner),
      this.hasFeature(BuildingFeature.GeminiSeamPartner),
      this.hasFeature(BuildingFeature.GeminiSentralPartner),
      this.hasFeature(BuildingFeature.GeminiPropkeyPartner),
      this.hasFeature(BuildingFeature.GeminiSwiftPartner),
      this.hasFeature(BuildingFeature.GeminiRiseIOPartner)
    ]).pipe(
      map(([geminiLatchPartnerEnabled, geminiJunePartnerEnabled, geminiHelloLandingPartnerEnabled,
            geminiCybersuitePartnerEnabled, geminiHelloLandingDevPartnerEnabled, geminiBehringPartnerEnabled,
            geminiBLinePartnerEnabled, geminiOrionHausPartnerEnabled, geminiJurnyPartnerEnabled,
            geminiElevatedLivingPartnerEnabled, geminiAirClutterPartnerEnabled, geminiTour24PartnerEnabled,
            geminiPynwheelPartnerEnabled, geminiLivlyPartnerEnabled, geminiCommunityBossPartnerEnabled,
            geminiPrimoPartnerEnabled, geminiEntratamationPartnerEnabled, geminiAvalonBaySelfGuidedTourPartnerEnabled,
            geminiChurchillPartnerEnabled, geminiVacasaPartnerEnabled, geminiSeamPartnerEnabled,
            geminiSentralPartnerEnabled, geminiPropkeyPartnerEnabled, geminiSwiftPartnerEnabled, geminiRiseIOPartnerEnabled]) =>
        geminiLatchPartnerEnabled || geminiJunePartnerEnabled || geminiHelloLandingPartnerEnabled ||
        geminiCybersuitePartnerEnabled || geminiHelloLandingDevPartnerEnabled || geminiBehringPartnerEnabled ||
        geminiBLinePartnerEnabled || geminiOrionHausPartnerEnabled || geminiJurnyPartnerEnabled ||
        geminiElevatedLivingPartnerEnabled || geminiAirClutterPartnerEnabled || geminiTour24PartnerEnabled ||
        geminiPynwheelPartnerEnabled || geminiLivlyPartnerEnabled || geminiCommunityBossPartnerEnabled ||
        geminiPrimoPartnerEnabled || geminiEntratamationPartnerEnabled || geminiAvalonBaySelfGuidedTourPartnerEnabled ||
        geminiChurchillPartnerEnabled || geminiVacasaPartnerEnabled || geminiSeamPartnerEnabled ||
        geminiSentralPartnerEnabled || geminiPropkeyPartnerEnabled || geminiSwiftPartnerEnabled || geminiRiseIOPartnerEnabled
      )
    );
  }

  public get getGeminiPartners$(): Observable<GeminiPartner[]> {
    return combineLatest([
      this.hasFeature(BuildingFeature.GeminiLatchPartner),
      this.hasFeature(BuildingFeature.GeminiJunePartner),
      this.hasFeature(BuildingFeature.GeminiHelloLandingPartner),
      this.hasFeature(BuildingFeature.GeminiCybersuitePartner),
      this.hasFeature(BuildingFeature.GeminiHelloLandingDevPartner),
      this.hasFeature(BuildingFeature.GeminiBehringPartner),
      this.hasFeature(BuildingFeature.GeminiBLinePartner),
      this.hasFeature(BuildingFeature.GeminiOrionHausPartner),
      this.hasFeature(BuildingFeature.GeminiJurnyPartner),
      this.hasFeature(BuildingFeature.GeminiElevatedLivingPartner),
      this.hasFeature(BuildingFeature.GeminiAirClutterPartner),
      this.hasFeature(BuildingFeature.GeminiTour24Partner),
      this.hasFeature(BuildingFeature.GeminiPynwheelPartner),
      this.hasFeature(BuildingFeature.GeminiLivlyPartner),
      this.hasFeature(BuildingFeature.GeminiCommunityBossPartner),
      this.hasFeature(BuildingFeature.GeminiPrimoPartner),
      this.hasFeature(BuildingFeature.GeminiEntratamationPartner),
      this.hasFeature(BuildingFeature.GeminiAvalonBaySelfGuidedTourPartner),
      this.hasFeature(BuildingFeature.GeminiChurchillPartner),
      this.hasFeature(BuildingFeature.GeminiVacasaPartner),
      this.hasFeature(BuildingFeature.GeminiSeamPartner),
      this.hasFeature(BuildingFeature.GeminiSentralPartner),
      this.hasFeature(BuildingFeature.GeminiPropkeyPartner),
      this.hasFeature(BuildingFeature.GeminiSwiftPartner),
      this.hasFeature(BuildingFeature.GeminiRiseIOPartner)
    ]).pipe(
      map(([geminiLatchPartnerEnabled, geminiJunePartnerEnabled, geminiHelloLandingPartnerEnabled,
            geminiCybersuitePartnerEnabled, geminiHelloLandingDevPartnerEnabled, geminiBehringPartnerEnabled,
            geminiBLinePartnerEnabled, geminiOrionHausPartnerEnabled, geminiJurnyPartnerEnabled,
            geminiElevatedLivingPartnerEnabled, geminiAirClutterPartnerEnabled, geminiTour24PartnerEnabled,
            geminiPynwheelPartnerEnabled, geminiLivlyPartnerEnabled, geminiCommunityBossPartnerEnabled,
            geminiPrimoPartnerEnabled, geminiEntratamationPartnerEnabled, geminiAvalonBaySelfGuidedTourPartnerEnabled,
            geminiChurchillPartnerEnabled, geminiVacasaPartnerEnabled, geminiSeamPartnerEnabled,
            geminiSentralPartnerEnabled, geminiPropkeyPartnerEnabled, geminiSwiftPartnerEnabled, geminiRiseIOPartnerEnabled]) => {
        const geminiPartners: GeminiPartner[] = [];

        if (geminiLatchPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Latch,
            value: getGeminiPartnerId(GeminiPartnerName.Latch)
          });
        }
        if (geminiJunePartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.June,
            value: getGeminiPartnerId(GeminiPartnerName.June)
          });
        }
        if (geminiHelloLandingPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.HelloLanding,
            value: getGeminiPartnerId(GeminiPartnerName.HelloLanding)
          });
        }
        if (geminiCybersuitePartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Cybersuite,
            value: getGeminiPartnerId(GeminiPartnerName.Cybersuite)
          });
        }
        if (geminiHelloLandingDevPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.LandingDev,
            value: getGeminiPartnerId(GeminiPartnerName.LandingDev)
          });
        }
        if (geminiBehringPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Behring,
            value: getGeminiPartnerId(GeminiPartnerName.Behring)
          });
        }
        if (geminiBLinePartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.BLine,
            value: getGeminiPartnerId(GeminiPartnerName.BLine)
          });
        }
        if (geminiOrionHausPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.OrionHaus,
            value: getGeminiPartnerId(GeminiPartnerName.OrionHaus)
          });
        }
        if (geminiJurnyPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Jurny,
            value: getGeminiPartnerId(GeminiPartnerName.Jurny)
          });
        }
        if (geminiElevatedLivingPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.ElevatedLiving,
            value: getGeminiPartnerId(GeminiPartnerName.ElevatedLiving)
          });
        }
        if (geminiAirClutterPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.AirClutter,
            value: getGeminiPartnerId(GeminiPartnerName.AirClutter)
          });
        }
        if (geminiTour24PartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Tour24,
            value: getGeminiPartnerId(GeminiPartnerName.Tour24)
          });
        }
        if (geminiPynwheelPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Pynwheel,
            value: getGeminiPartnerId(GeminiPartnerName.Pynwheel)
          });
        }
        if (geminiLivlyPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Livly,
            value: getGeminiPartnerId(GeminiPartnerName.Livly)
          });
        }
        if (geminiCommunityBossPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.CommunityBoss,
            value: getGeminiPartnerId(GeminiPartnerName.CommunityBoss)
          });
        }
        if (geminiPrimoPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Primo,
            value: getGeminiPartnerId(GeminiPartnerName.Primo)
          });
        }
        if (geminiEntratamationPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Entratamation,
            value: getGeminiPartnerId(GeminiPartnerName.Entratamation)
          });
        }
        if (geminiAvalonBaySelfGuidedTourPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.AvalonBaySelfGuidedTour,
            value: getGeminiPartnerId(GeminiPartnerName.AvalonBaySelfGuidedTour)
          });
        }
        if (geminiChurchillPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Churchill,
            value: getGeminiPartnerId(GeminiPartnerName.Churchill)
          });
        }
        if (geminiVacasaPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Vacasa,
            value: getGeminiPartnerId(GeminiPartnerName.Vacasa)
          });
        }
        if (geminiSeamPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Seam,
            value: getGeminiPartnerId(GeminiPartnerName.Seam)
          });
        }
        if (geminiSentralPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Sentral,
            value: getGeminiPartnerId(GeminiPartnerName.Sentral)
          });
        }
        if (geminiPropkeyPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Propkey,
            value: getGeminiPartnerId(GeminiPartnerName.Propkey)
          });
        }
        if (geminiSwiftPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.Swift,
            value: getGeminiPartnerId(GeminiPartnerName.Swift)
          });
        }
        if (geminiRiseIOPartnerEnabled) {
          geminiPartners.push({
            name: GeminiPartnerName.RiseIO,
            value: getGeminiPartnerId(GeminiPartnerName.RiseIO)
          });
        }
        return geminiPartners;
      })
    );
  }

  public get hasPmsCredentialsFeature$(): Observable<boolean> {
    return this.hasSplitFeature('pms_credentials');
  }

  public get hasOpenkitMultiPartnerFeature$(): Observable<boolean> {
    return this.hasSplitFeature('openkit_multi_partner');
  }

  public get hasSelfSetupFeature$(): Observable<boolean> {
    return this.hasSplitFeature('self_setup');
  }

  public get hasActivityFeature$(): Observable<boolean> {
    return this.hasFeature(BuildingFeature.Activity);
  }

  public get hasOpenkitOnboardingV2Feature(): Observable<boolean> {
    return this.hasSplitFeature('openkit_onboarding_v2');
  }

  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private splitService: SplitService,
  ) {
    if (environment.split) {
      this.splitService.init(environment.split).subscribe();
    }
  }

  public getAllFeatures(): Observable<BuildingFeature[] | undefined> {
    return this.selectedBuildingsService.getSelectedBuilding().pipe(
      map(building => building?.features),
    );
  }

  public hasFeature(feature: BuildingFeature): Observable<boolean> {
    return this.selectedBuildingsService.getSelectedBuilding().pipe(
      map(building => building?.features),
      map(features => !!features && features.some(x => x === feature)),
    );
  }

  public hasSplitFeature(featureName: string, treatmentEnabledValue: SplitIO.Treatment = 'on'): Observable<boolean> {
    if (this.splitService.sdkReady$) {
      return this.splitService.sdkReady$.pipe(
        map(() => {
          const treatment: SplitIO.Treatment = this.splitService.getTreatment(featureName);
          return treatment === treatmentEnabledValue;
        }),
      );
    } else {
      return of(false);
    }
  }

  public hasFeatures(features: BuildingFeature[]): Observable<boolean[]> {
    return this.selectedBuildingsService.getSelectedBuilding().pipe(
      map(building => building?.features),
      map(buildingFeatures => features.map(feature => buildingFeatures?.some(x => x === feature) ?? false)),
    );
  }
}
